// Generated by Framer (d213f04)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import ElementsActivityIndicator from "./rQ6eqfFN0";
const ElementsActivityIndicatorFonts = getFonts(ElementsActivityIndicator);

const cycleOrder = ["p0QXTU9oK", "TgvBUfXrB"];

const variantClassNames = {p0QXTU9oK: "framer-v-axn3a", TgvBUfXrB: "framer-v-sucyh6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {day: "p0QXTU9oK", night: "TgvBUfXrB"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "p0QXTU9oK"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "p0QXTU9oK", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isDisplayed = () => {
if (baseVariant === "TgvBUfXrB") return false
return true
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2f4d4", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-axn3a", className)} data-framer-name={"day"} layoutDependency={layoutDependency} layoutId={"p0QXTU9oK"} ref={ref} style={{...style}} {...addPropertyOverrides({TgvBUfXrB: {"data-framer-name": "night"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter-SemiBold\", \"Inter\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "600", "--framer-line-height": "24px", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Currently 🧑🏼‍💻 working</motion.p></React.Fragment>} className={"framer-wvb0yd"} data-framer-name={"Availability"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"AUTlClCI1"} style={{"--extracted-r6o4lv": "var(--token-72be4b58-ad30-40d4-9403-e97ffbb2d1b3, rgb(246, 246, 246))", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({TgvBUfXrB: {children: <React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter-Medium\", \"Inter\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-line-height": "24px", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Currently 💤 </motion.p></React.Fragment>, fonts: ["Inter-Medium"]}}, baseVariant, gestureVariant)}/>{isDisplayed() && (<motion.div className={"framer-a5ly3g-container"} layoutDependency={layoutDependency} layoutId={"MCD2Qwpir-container"}><ElementsActivityIndicator height={"100%"} id={"MCD2Qwpir"} layoutId={"MCD2Qwpir"} style={{height: "100%", width: "100%"}} variant={"CeChT3d1P"} width={"100%"}/></motion.div>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2f4d4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2f4d4 .framer-ib2u2o { display: block; }", ".framer-2f4d4 .framer-axn3a { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-2f4d4 .framer-wvb0yd { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-2f4d4 .framer-a5ly3g-container { flex: none; height: 8px; position: relative; width: 8px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2f4d4 .framer-axn3a { gap: 0px; } .framer-2f4d4 .framer-axn3a > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-2f4d4 .framer-axn3a > :first-child { margin-left: 0px; } .framer-2f4d4 .framer-axn3a > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 155
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"TgvBUfXrB":{"layout":["auto","auto"]}}}
 */
const FramerlbkVZcdld: React.ComponentType<Props> = withCSS(Component, css, "framer-2f4d4") as typeof Component;
export default FramerlbkVZcdld;

FramerlbkVZcdld.displayName = "Elements/Availability";

FramerlbkVZcdld.defaultProps = {height: 24, width: 155};

addPropertyControls(FramerlbkVZcdld, {variant: {options: ["p0QXTU9oK", "TgvBUfXrB"], optionTitles: ["day", "night"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerlbkVZcdld, [...ElementsActivityIndicatorFonts])